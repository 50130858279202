import { createRouter, createWebHistory } from 'vue-router'
// import Home from './views/Home.vue'
import Cart from './views/Cart.vue'

const routes = [
  {
    path: '/',
    name: 'cat-minipc',
    component: () => import(/* webpackChunkName: "cat-minipc" */ './views/Category_MiniPCs.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
  },
  {
    path: '/item/:SKU_uuid',
    name: 'item_details',
    component: () => import(/* webpackChunkName: "item" */ './views/ItemDetails.vue')
  },
  {
    path: '/cart/paymentstatus',
    name: 'payment_status',
    component: () => import(/* webpackChunkName: "payment-complete" */ './views/PaymentStatus.vue')
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
