
// export function format_locale_price({value,currency}) {
//     return value.toString() +'~'+ currency.toString()
// }

function number_with_commas(value) {
    if (value==undefined) {
        console.error('undefined value passed to number_with_commas');
        return ""
    }
    const show_comams = false;
    if (show_comams) {
        var parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    } else {
        return value
    }
}

export const helperMixins = {
    methods: {
        format_locale_price({value,currency}) {
            if (currency == 'SGD') {
                return 'S$ ' + number_with_commas(value);
            } else {
                return currency + ' ' + number_with_commas(value);
            }
        },
        number_with_commas(value) {return number_with_commas(value)},
    }
  }
