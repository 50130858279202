<template>
  <div class='max-w-screen-lg border rounded p-5 m-1 my-3 flex flex-col'>
    <span class='SKU_title text-xl font-medium text-left'>{{ SKU_with_variants.main.SKU_title }}</span>
    <div class="flex flex-cols flex-wrap items-start">
      
      <!-- Images half -->
      <div class="div_images flex px-2 w-full md:w-1/2">
        <div class='sku_img_wrapper flex items-center' v-for="(image_detail, image_index) in SKU_with_variants.images" :key="image_index">
          <img class='SKU_image w-max h-max' :src=image_detail.image_uri>
        </div>
      </div>
      
      <!-- Cart options half -->
      <div class="div_sku_variants flex w-full md:w-1/2">
        <table class='border-collapse border text-left text-xs'>
          <tr class='text-xs'>
            <th >Options</th>
            <th>Price</th>
            <th class='text-right'>Details</th>
          </tr>
          <tr class='hover:bg-gray-100' v-for="(variant_details, variant_index) in SKU_with_variants.variants" :key="variant_index">
            
            <td>{{variant_details.main.SKU_description}}</td>
            <td class='text-right'>
                <template v-if="variant_index==0">{{ this.$root.format_locale_price(variant_details.price) }}</template>
                <template v-else>{{ this.$root.number_with_commas(variant_details.price.value) }}</template>
            </td>
            <td class='hyperlink_text' v-on:click='visit_details(variant_details.main.SKU_uuid)'>
                  <!-- <router-link :to="{name:'item_details',params:{SKU_uuid:variant_details.main.SKU_uuid} }" class=''>{{variant_details.main.SKU_uuid}}</router-link> -->
                  <router-link :to="{name:'item_details',params:{SKU_uuid:variant_details.main.SKU_uuid}}">{{variant_details.main.SKU_uuid}}</router-link>
            </td>

            
          </tr>
        </table>
      </div>
    </div>
    
  </div>

</template>

<script>
export default {
  name: 'GenericSKUParent',
  props: {
    SKU_with_variants : Object
  },
  methods: {
    visit_details(SKU_uuid) {
      this.$router.push({name:'item_details',params:{SKU_uuid:SKU_uuid}});
    }
  },
  mounted() {
    //console.log('GenericSKUParent loaded with',this.SKU_with_variants);
  },

}
</script>


<style scoped>
  div.sku_img_wrapper{
    width: 150px;
    height: 150px;
  }
  img.SKU_image {
    /* max-width:250px; */
    /* max-height:250px; */
    /* border:1px solid green; */
  }



</style>