<template>
  <div class="about text-left text-sm px-2">
    <div class='text-lg font-bold pb-2'>About us</div>
    <div class='text_description px-4'>We strive to support IT professionals by providing easy access to hardware and services that meet your technical and institutional requirements.<br>Please contact us at contact<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" /></svg>abounddata.com if you are looking for additional products or services beyond our online offerings.</div>

  <div class='text-lg font-bold pt-6 pb-2'>FAQs</div>
  <div class='px-4'>

    <div class='faq_q'>Do you accept purchase orders from my company/institution?</div>
    <div class='faq_a'>Yes, we accept purchase orders.<br>The payment terms are <span class='italic'>prepayment in advance</span> by default as we are unable to offer credit (NET 30) terms to new customers. We can offer <span class='italic'>NET 30</span> terms only to customers with sufficient purchase history.</div>

    <div class='faq_q'>What are the delivery terms and charges?</div>
    <div class='faq_a'>Delivery is free islandwide within Singapore. Please contact us for international orders.</div>

    <div class='faq_q'>What is your warranty policy?</div>
    <div class='faq_a'>Our products are covered against manufacturer's defects for a period of one year from purchase. The warranty does not cover defects or malfunction caused by accident, misuse or abuse.</div>

    <div class='faq_q'>What is the estimated time to delivery after order?</div>
    <div class='faq_a'>For most products, final assembly is done after order confirmation and payment. The orders will be ready for shipping after about 4-8 working days, with eventual delivery about 2-3 weeks after order.</div>

  </div>

  </div>
</template>


<style scoped>
  .faq_q {
    @apply font-bold pb-1;
  }

  .faq_a {
    @apply px-4 pb-6 text-gray-600;

  }
</style>