<template>
  <div class='p-2 flex flex-col items-start'>

  <button type="button" @click="$router.go(-1)" class="my-5 btn btn-outline-success">&laquo; Back</button>

    <template v-if="item_details!==null">
      <!-- <div class='text-xs'>{{item_details}}</div> -->
      <div class="text-xl text-gray-700 font-bold" >{{item_details.main.SKU_title}}</div>
      <!-- <div class="text-xs text-gray-500">SKU: {{item_details.main.SKU_uuid}}</div> -->

      <StandaloneSKUAdd :item_details="item_details" :SKU_uuid="item_details.main.SKU_uuid" />
      <!-- Images -->
      <div class="div_images flex px-2">
        <div class='sku_img_wrapper flex items-center' v-for="(image_detail, image_index) in item_details.images" :key="image_index">
          <img class='SKU_image w-max h-max' :src=image_detail.image_uri>
        </div>
      </div>

      <table class='param_table text-sm text-left table-fixed'>
        <tr class='break-normal' v-for="(param_detail, param_detail_index) in item_details.params" v-bind:key=param_detail_index>
          <template v-if='param_detail.param_name=="External review" && param_detail.param_value.includes("youtube")'>
            <!-- Special display style for Youtube reviews -->
            <td class=''>YouTube review</td>
            <td class='whitespace-pre-wrap'>
              <iframe width="355" height="200" :src="get_embedded_Youtube_link(param_detail.param_value)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <a :href='param_detail.param_value' target='_blank'>Watch on Youtube <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg></a>
              </td>
          </template>
          <template v-else>
            <!-- Default display style for the param details -->
            <td class=''>{{ param_detail.param_display_name || param_detail.param_name}}</td>
            <td class='whitespace-pre-wrap'>{{ param_detail.param_value }}</td>
          </template>
        </tr>
        <!-- <tr><td>SKU ID</td><td>{{$route.params.SKU_uuid}}</td></tr> -->
        <!-- <tr><td>Price ({{ item_details.price.currency }})</td><td>{{ item_details.price.value }}</td></tr> -->

      </table>

      <StandaloneSKUAdd class='mt-2 mb-10' :item_details="item_details" :SKU_uuid="item_details.main.SKU_uuid" />
      

    </template>
    
  </div>
</template>

<script>
// @ is an alias to /src
import StandaloneSKUAdd from '@/components/StandaloneSKUAdd.vue'

export default {
  name: 'ItemDetails',
  components: {
    StandaloneSKUAdd
  },
  data() {
    return {
      item_details: null,
    };
  },
  methods: {
    increment_cart_item() {
      if (('main' in this.item_details) && ('SKU_uuid' in this.item_details.main)) {
        this.$store.commit('increment_to_cart',this.item_details);
      } else {
        console.error('User attempted cart addition without the item being loaded on page.');
      }
    },
    decrement_cart_item() {
      this.$store.commit('decrement_from_cart', this.$route.params.SKU_uuid);
    },
    async fetch_SKU_details() {
      const response = await fetch(this.api_root.replace(/\/$/, "") + "/api/sku/get_details/" + this.$route.params.SKU_uuid.toString(),{credentials: 'include',})
      this.item_details = await response.json();
    },
    get_embedded_Youtube_link(generic_youtube_link) {
      // Possible form: 'https://www.youtube.com/watch?v=pNk-r1tr1dc&t=270s'
      // Possible form: 'https://youtu.be/pNk-r1tr1dc?t=270'
      // Target form: 'https://www.youtube.com/embed/pNk-r1tr1dc?start=270'
      if (generic_youtube_link.includes('youtube.com/embed/')) {
        return generic_youtube_link;
      } else if (generic_youtube_link.includes('youtu.be/')) {
        console.error('Conversion from youtub.be not implemented');
        return 'http://www.example.com' // Not implemented
      } else if (generic_youtube_link.includes('youtube.com/watch?')) {
        const youtube_video_id = generic_youtube_link.match(/v=([a-zA-Z\-0-9]{11})/);
        const start_time = generic_youtube_link.match(/(?<![a-zA-Z])t=([0-9]+)[s]{0,1}/);
        if (start_time) {
          return 'https://www.youtube.com/embed/' + youtube_video_id[1] + '?start=' + start_time[1];
        } else {
          return 'https://www.youtube.com/embed/' + youtube_video_id[1];
        }
      } else {
        console.warn('Youtube link not in recognisable form.');
        return generic_youtube_link;
     }
    },
  },
  async created() {
    this.fetch_SKU_details();
  }  
}
</script>


<style scoped>
  .param_table {
    max-width:600px;
  }
</style>