import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist' // Added after installing vuex-persist
import createMutationsSharer from "vuex-shared-mutations";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
  })

const mutations_sharer = createMutationsSharer({ predicate: ["clear_cart","add_item_to_cart","increment_cart_line","decrement_cart_line","replace_cart"] });

function get_current_SKU_count(state, requested_SKU_uuid) {
    var current_count = state.cart_contents.filter(x => x.SKU.main.SKU_uuid == requested_SKU_uuid);
    if (current_count.length == 0) {
        return 0
    } else {
        return current_count[0].count;
    }
}

export default createStore({
  state: {
      cart_contents: []
  },
  mutations: {
      clear_cart(state) {
          state.cart_contents = []
          console.log('Cart is cleared');
      },
      add_item_to_cart(state,item) {
        var current_count = state.cart_contents.filter(x => x.SKU.main.SKU_uuid == item.main.SKU_uuid);
        // Checks whether items already exists in cart. If so, we increase the quantity by +1
        if (current_count.length > 0) {
            state.cart_contents.filter(x => x.SKU.main.SKU_uuid == item.main.SKU_uuid)[0].count += 1;
        } else {
            state.cart_contents.push({'SKU':item,'count':1});
        }  
      },
      increment_cart_line(state,SKU_uuid) {
        // console.log('Incrementing cart with',item,state.cart_contents);

        var current_count = state.cart_contents.filter(x => x.SKU.main.SKU_uuid == SKU_uuid);
        // Checks whether items already exists in cart. If so, we increase the quantity by +1
        if (current_count.length > 0) {
            state.cart_contents.filter(x => x.SKU.main.SKU_uuid == SKU_uuid)[0].count += 1;
        } else {
            console.error('Unable to increment item count because SKU does not exist in cart.')
        }
      },
      decrement_cart_line(state,SKU_uuid) {
        var current_count = get_current_SKU_count(state, SKU_uuid);
        if (current_count > 0) {
            state.cart_contents.filter(x => x.SKU.main.SKU_uuid == SKU_uuid)[0].count -= 1
            state.cart_contents = state.cart_contents.filter(x => x.count > 0); // If the cart count for any line is non-positive, drop that line
        } else {
            console.error('Unable to decrement item count because SKU does not exist in cart.')
        }
      },
      replace_cart(state,modified_cart) {
          state.cart_contents = modified_cart;
      }
  },
  getters: {
    get_current_SKU_count: (state) => (requested_SKU_uuid) => {
        return get_current_SKU_count(state, requested_SKU_uuid)
    },
    get_total_cart_locale_value: (state) => {
        return {currency:state.cart_contents[0].SKU.price.currency,value:state.cart_contents.map(cart_line => cart_line.SKU.price.value * cart_line.count).reduce((a,b)=>a+b,0)}
    },
    get_cart_essential_details: (state) => {
        // This is to minimise bandwidth where both sides of the connection are aware of the SKU details,
        // Both sides shall verify the details so that skilled customers aren't giving themselves discounts.
        return {'items':state.cart_contents.map(cart_line => ({'count':cart_line.count,'SKU_uuid':cart_line.SKU.main.SKU_uuid,'price':cart_line.SKU.price})),
                'total_locale_value':{currency:state.cart_contents[0].SKU.price.currency,value:state.cart_contents.map(cart_line => cart_line.SKU.price.value * cart_line.count).reduce((a,b)=>a+b,0)},
                'total_item_count':state.cart_contents.map(cart_line => cart_line.count).reduce((a,b)=>a+b,0)}
    }
  },
  actions: {
    add_item_to_cart({state,commit}, item) {
        var SKU_of_interest = state.cart_contents.filter(x => x.SKU.main.SKU_uuid == item.main.SKU_uuid);
        if (SKU_of_interest.length > 0) {
            if (get_current_SKU_count(state, item.main.SKU_uuid) + 1 <= SKU_of_interest[0].SKU.main.current_stock) {
                commit('add_item_to_cart',item);
                return {'status':'success','message':null};
            } else {
                // Then we cannot add more because we have reached the max stock limit
                return {'status':'failure','message':'Max order quantity is ' + SKU_of_interest[0].SKU.main.current_stock.toString() + '.'};
            }
        } else {
            commit('add_item_to_cart',item);
            return {'status':'success','message':null};
        }
    },
    increment_cart_line({state,commit}, SKU_uuid) {
        var SKU_of_interest = state.cart_contents.filter(x => x.SKU.main.SKU_uuid == SKU_uuid);
        if (SKU_of_interest.length > 0) {
            if (get_current_SKU_count(state, SKU_uuid) + 1 <= SKU_of_interest[0].SKU.main.current_stock) {
                commit('increment_cart_line',SKU_uuid);
                return {'status':'success','message':null};
            } else {
                // Then we cannot add more because we have reached the max stock limit
                return {'status':'failure','message':'Max order quantity is ' + SKU_of_interest[0].SKU.main.current_stock.toString() + '.'};
            }
        } else {
            return {'status':'failure','message':'This SKU_uuid is not in the cart yet.'};
        }
    },    
    decrement_cart_line({commit}, SKU_uuid) {
        commit('decrement_cart_line',SKU_uuid);
        return {'status':'success','message':null};
    },    
  },
  modules: {
  },
  plugins: [vuexLocal.plugin, mutations_sharer]
})
