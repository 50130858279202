<template>
  <div class="flex flex-col items-center rounded">

    <!-- Quantity count row -->
    <div class="text-lg h-8 w-8">
      {{ this.$store.getters.get_current_SKU_count(SKU_uuid) }}
    </div>

    <!-- Quantity change row -->
    <div class="flex border border-gray-100 bg-white rounded">
      <div class="cart_change_button" v-bind:onclick="decrement">
        &minus;
      </div>&nbsp;
      <div class="cart_change_button" v-bind:onclick="increment">
        &plus;
      </div>
    </div>
    
    <!-- Error message row -->
    <div>
      <span class='transition-all duration-500 text-left text-red-500 italic text-sm'>{{errormessage}}</span>
    </div>

  </div>  
</template>

<script>
export default {
  name: 'CartQtyChange',
  props: {
    SKU_uuid : String,
  },
  data() {
    return {
      errormessage : '',
    }
  },
  components: {
    
  },
  methods: {
    async decrement() {
      const response = await this.$store.dispatch('decrement_cart_line',this.SKU_uuid);
      if (response.status=='failure') {
        this.errormessage = response.message;
      } else {
        this.errormessage = "";
      }

    },
    async increment() {
      const response = await this.$store.dispatch('increment_cart_line',this.SKU_uuid);
      if (response.status=='failure') {
        this.errormessage = response.message;
      } else {
        this.errormessage = "";
      }
    }
  }
}



</script>

<style scoped>
  .cart_change_button {
    @apply text-base h-6 w-6 border rounded border-gray-700 hover:bg-gray-200 active:bg-red-200 cursor-pointer
  }
</style>
