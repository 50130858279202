<template>
  <div class='px-1'>
    <div class='text-left text-xl font-bold px-1'>Mini PCs</div>
    <div class='category_description px-10 py-2 text-left text-xs text-gray-500 whitespace-pre-line mb-2'>
      <div class='pb-1'>
        By careful selection of high-end electronics components with small footprints for assembly, a small mini PC can now be packed with the speed and performance that matches that of a much larger desktop or laptop. To meet gaming or computation applications, even a high-end graphics card can be included within the small form-factor of a mini PC (see selected models).        
      </div>
      <div class='pb-1'>
        Unlike a full desktop PC, a mini PC is small enough to put on a work table without taking up much space. For a more compact solution, the mini PC can also be mounted behind a monitor, using VESA mounts purchased separately, so that they are hidden from view and take up no table space.
      </div>
    </div>


    <div class='flex flex-col items-center'>
        <div class='w-full' v-for="(parent_SKU, parent_SKU_index) in page_SKUs" :key="parent_SKU_index">
            <GenericSKUParent :SKU_with_variants=parent_SKU />

        </div>

    </div>
  </div>
</template>

<script>
import GenericSKUParent from '@/components/GenericSKUParent.vue'

export default {
  name: 'Home',
  components: {
    GenericSKUParent,
  },
  data() {
    return {
      category_id: 1,
      page_SKUs: [],
    }
  },
  methods: {
    async fetch_page_SKUs() {
      const response = await fetch(this.api_root.replace(/\/$/, "") + "/api/sku/get_category_skus/" + this.category_id.toString(),{credentials: 'include',})
      this.page_SKUs = await response.json();
    }
  },
  async created() {
    this.fetch_page_SKUs();
  }

}
</script>



