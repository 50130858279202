<template>
  <div class='flex-col flex items-start text-sm text-gray-700 border-2 rounded border-gray-400 p-2'>


    <div class='text-left'>
      <label class='single_col_label' for="RFQCustomerEmail">Email:<span class='required_asterisk'><sup>(required)</sup></span></label>
      <!-- <input aria-required="true" class='w-60' type="text" id="RFQCustomerEmail" v-model="RFQForm.Email"> -->
      <input class='single_col_field' aria-required="true" type="text" id="RFQCustomerEmail" v-model="RFQForm.Email" v-bind:class="{field_has_error:v$.RFQForm.Email.$errors.length>0}">
    </div>
    <div class='error_messages' v-if="v$.RFQForm.Email.$errors.length>0">{{ v$.RFQForm.Email.email.$message }}</div>

    <div class='text-left flex flex-col sm:flex-row'>
      <div>
        <label class='double_col_label' for="RFQCustomerFirstname">First name:</label>
        <input class='double_col_field' type="text" id="RFQCustomerFirstname" v-model="RFQForm.Firstname">
      </div>
      <div>
        <label class='double_col_label' for="RFQCustomerLastname">Last name:</label>
        <input class='double_col_field' type="text" id="RFQCustomerLastname" v-model="RFQForm.Lastname">
      </div>
    </div>

    <div class='text-left'>
      <label class='single_col_label' for="RFQCustomerCompanyName">Company name:</label>
      <input class='single_col_field' type="text" id="RFQCustomerCompanyName" v-model="RFQForm.CompanyName">
    </div>

    <div class='border flex flex-col items-start  my-1 p-1'>
      <span class='font-bold text-gray-700'>Shipping address</span>
      <div class='pl-10 flex flex-col items-start'>
          
        <div class='text-left'>
          <label class='single_col_label' for="RFQCustomerShippingAddressLine1">Address Line 1:</label>
          <input class='single_col_field' type="text" id="RFQCustomerShippingAddressLine1" v-model="RFQForm.ShippingAddressLine1">
          <!-- <label for="RFQCustomerShippingAddressUnit">Unit no.:</label> -->
          <!-- <input class='w-30' type="text" id="RFQCustomerShippingAddressUnit" placeholder='e.g. #01-01' v-model="RFQForm.ShippingAddressUnit"> -->
        </div>

        <div class='text-left'>
          <label class='single_col_label' for="RFQCustomerShippingAddressLine2">Address Line 2:</label>
          <input class='single_col_field' type="text" id="RFQCustomerShippingAddressLine2" v-model="RFQForm.ShippingAddressLine2">
        </div>


        <div class='text-left flex flex-col sm:flex-row'>
          <div>          
            <span class='double_col_label'>Country:</span>
            <input class='double_col_field' type="text" id="RFQCustomerShippingAddressCountry" v-model="RFQForm.ShippingAddressCountry" disabled>
          </div>
          <div>
            <label class='double_col_label' for="RFQCustomerShippingAddressPostal">Postal code:</label>
            <input class='double_col_field postal_code_field' type="text" id="RFQCustomerShippingAddressPostal" v-model="RFQForm.ShippingAddressPostal">
          </div>
        </div>


        <div>

        </div>
      </div>
    </div>


    <div class='border flex flex-col items-start my-1 p-1'>
      <span class='font-bold text-gray-700'>Billing address</span>

      <div class='pl-10 flex flex-col items-start'>

        <div class='text-left'>
          <input class='ml-1 mr-2' type="checkbox" id="RFQCustomerBillingEqualsShipping" v-model="RFQForm.BillingEqualsShipping">  
          <label class='' for="RFQCustomerBillingEqualsShipping">Billing same as shipping address.</label>
        </div>

        <div class='text-left'>
          <label class='single_col_label' for="RFQCustomerBillingAddressLine1">Address Line 1:</label>
          <input v-if='RFQForm.BillingEqualsShipping' class='single_col_field' type="text" v-model="RFQForm.ShippingAddressLine1" disabled>
          <input v-else class='single_col_field' type="text" id="RFQCustomerBillingAddressLine1" v-model="RFQForm.BillingAddressLine1">
          <!-- <label for="RFQCustomerBillingAddressUnit">Unit no.:</label> -->
          <!-- <input class='w-30' type="text" id="RFQCustomerBillingAddressUnit" placeholder='e.g. #01-01' v-model="RFQForm.BillingAddressUnit"> -->
        </div>

        <div class='text-left'>
          <label class='single_col_label' for="RFQCustomerBillingAddressLine2">Address Line 2:</label>
          <input v-if='RFQForm.BillingEqualsShipping' class='single_col_field' type="text" v-model="RFQForm.ShippingAddressLine2" disabled>
          <input v-else class='single_col_field' type="text" id="RFQCustomerBillingAddressLine2" v-model="RFQForm.BillingAddressLine2">
        </div>


        <div class='text-left flex flex-col sm:flex-row'>
          <div> 
            <span class='double_col_label'>Country:</span>
            <input class='double_col_field' type="text" id="RFQCustomerBillingAddressCountry" v-model="RFQForm.BillingAddressCountry" disabled>
          </div>            
          <div>
            <label class='double_col_label' for="RFQCustomerBillingAddressPostal">Postal code:</label>
            <input v-if='RFQForm.BillingEqualsShipping' class='double_col_field postal_code_field' type="text" v-model="RFQForm.ShippingAddressPostal" disabled>
            <input v-else class='double_col_field postal_code_field' type="text" id="RFQCustomerBillingAddressPostal" v-model="RFQForm.BillingAddressPostal">
          </div>
        </div>


        <div>

        </div>
      </div>
    </div>


    <!-- <p style="white-space: pre-line;">{{ message }}</p> -->
    <br>

    <label class='single_col_label' for="RFQCustomerNotes">Any additional notes:</label><br>
    <textarea maxlength='200' class='border text-sm border-gray-400 px-1 resize-y w-96 max-h-28' name='RFQCustomerNotes' v-model="RFQForm.Notes" placeholder="200 characters max"></textarea>
   
  <button v-if='(RFQ_response==null || RFQ_response.status!=="success")' class='major_action_button  self-end m-1 mt-10' @click='submit_requestquotation'>
    <template v-if='!RFQ_button_isclicked'>Submit request</template>
    <template v-else>
      <div class="flex items-center justify-center pt-3 pb-1 px-4 space-x-2 animate-bounce">
          <div class="w-1 h-1 bg-blue-700 rounded-full"></div>
          <div class="w-1 h-1 bg-blue-700 rounded-full"></div>
          <div class="w-1 h-1 bg-blue-700 rounded-full"></div>
      </div>
    </template>
  </button>
  <span v-else class='text-red-900 place-self-end text-right mt-5'>
    <template v-if='RFQ_response.status=="success"'>
      Thank you, we have received your request.<br>Our team will provide a quotation by email as soon as possible.<br>
      <span class='text-xs opacity-60 italic'>(Cart {{RFQ_response.cart_uuid}})</span>
    </template>
    <template v-else>
      Error: {{this.RFQ_response.message}}
    </template>
  </span>

  </div>

  <!-- <div class="input-errors" v-for="error of v$.$errors" :key="error.$uid"> -->
    <!-- <div class="error-msg">{{ error.$message }}</div> -->
  <!-- </div> -->



</template>


<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: 'RequestQuotation',
  components: {

  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      RFQForm: {
        Notes: '',
        Email: '', //'test@example.com',
        Firstname: '',
        Lastname: '',
        CompanyName: '',
        ShippingAddressLine1: '',
        ShippingAddressLine2: '',
        ShippingAddressPostal: '',
        ShippingAddressUnit: '',
        ShippingAddressCity: '',
        ShippingAddressPhone: '',
        ShippingAddressCountry: 'Singapore',
        BillingEqualsShipping: false,
        BillingAddressLine1: '',
        BillingAddressLine2: '',
        BillingAddressPostal: '',
        BillingAddressUnit: '',
        BillingAddressCity: '',
        BillingAddressCountry: 'Singapore',
        BillingAddressPhone: '',
      },
      RFQ_button_isclicked: false,
      RFQ_response: null,
    }
  },
  validations() {
    return {
      RFQForm: {
        Email: { required, email }, // Matches this.asd
        Firstname: {  }, // Matches this.asd
        Lastname: {  },
      }
    }
  },
  methods: {
    async submit_requestquotation() {
      // console.log(this.v$);
      // console.log(this.v$.RFQForm.Email);
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
          this.RFQ_button_isclicked = true;
          await new Promise(r => setTimeout(r, 500));
          const response = await fetch(this.api_root.replace(/\/$/, "") + "/api/carts/requestquote",{
            method: 'POST',
            credentials: 'include',
            headers: {'Accept':'application/json','Content-Type':'application/json'},
            //body: JSON.stringify({'RFQForm':this.RFQForm,'cart':this.$store.state.cart_contents})});
            body: JSON.stringify({'RFQForm':this.RFQForm,'cart':this.$store.getters['get_cart_essential_details']})});
          const content = await response.json();
          this.RFQ_response = content;
      }
    },
  },

}
</script>

<style scoped>
  input[type=text] {
    @apply border border-gray-400 rounded-sm mx-1 px-1 hover:border-blue-600 hover:bg-blue-100;
  }
  textarea {
    @apply border border-gray-400 rounded-sm mx-1 px-1 hover:border-blue-600 hover:bg-blue-100;
  }
  .field_has_error {
    @apply border-red-600 bg-red-200
  }
  .error_messages {
    @apply text-sm italic text-red-600
  }
  .required_asterisk {
    @apply text-red-600 font-bold tracking-wide italic
  }
  .double_col_field,.single_col_field,.single_col_label,.double_col_label {
    display:inline-block;
    text-align: left;
    @apply m-1;
  }
  .single_col_label {
    width:150px;
  }
  .single_col_field {
    width:297px;
  }
  .double_col_label {
    /* width:10px; */
  }
  .double_col_field {
    width:150px;
  }
  .single_col_field.postal_code_field {
    width:80px;
  }
  :disabled {
     @apply bg-gray-200 text-gray-700;
  }

  .major_action_button {
    @apply text-base rounded border-2 border-gray-400 py-1 px-2 bg-blue-50 hover:bg-blue-200 font-semibold;
    @apply transform active:translate-y-0.5 translate-x-px active:border-gray-700;
  }
</style>