<template>
  <div class='max-w-5xl'>

    <div v-if="this.$store.state.cart_contents.length==0">Your cart is empty.</div>
    <table class='m-1'>
      <tr>
        <th>SKU</th>
        <th>Description</th>
        <th>Item cost</th>
        <th>Qty</th>
        <th>Line cost</th>
      </tr>
      <tr class='text-sm' v-for="(cart_line, cart_line_index) in this.$store.state.cart_contents" :key="cart_line_index">
        <td class=''>
          <!-- Clickable SKU -->
          <template v-if='!(showing_payment_div||showing_RFQ_div)'>
            <router-link  class='text-blue-700 hover:underline' :to="{name:'item_details',params:{SKU_uuid:cart_line.SKU.main.SKU_uuid }}">{{ cart_line.SKU.main.SKU_uuid }}</router-link>
          </template>
          <!-- Non-clickable SKU -->
          <template v-else>{{ cart_line.SKU.main.SKU_uuid  }}</template>
        </td>
        
        

        <td class=''>
          <div class='flex flex-col md:flex-row md:items-center items-start'>
            <ExpandingDiv>
              <img class='cart_thumbnail m-2 mr-3' :src='cart_line.SKU.images[0].image_uri' v-show='!(showing_payment_div||showing_RFQ_div)'>
            </ExpandingDiv>
            <div class='flex flex-col items-start'>
              <div class='text-left'>{{ cart_line.SKU.main.SKU_title }}</div>
              <div class='pt-1 md:pt-0 text-left text-xs text-gray-500'>{{ cart_line.SKU.main.SKU_description }}</div>
            </div>
          </div>
        </td>

        
        <td class=''>
          {{ this.$root.format_locale_price(cart_line.SKU.price) }}
        </td>
        <td class=''>
          <CartQtyChange v-if='!(showing_payment_div||showing_RFQ_div)' :SKU_uuid="cart_line.SKU.main.SKU_uuid" />
          <span v-else>{{cart_line.count}}</span>
        </td>
        <td class=''>
          {{ this.$root.format_locale_price({value:cart_line.SKU.price.value*cart_line.count,currency:cart_line.SKU.price.currency}) }}
        </td>
      </tr>
      <tr class=''>
        <td colspan="5" class='border-0 py-1'>
          <div class="flex flex-row justify-between items-center">
            
            <!-- Buttons to expand or clear cart -->
            <!-- Active clear cart -->
            <div class='' v-if='(!(showing_payment_div||showing_RFQ_div)) && this.$store.state.cart_contents.length >0'>
              <div v-on:click="clear_cart" class="flex flex-row border-2 rounded hover:bg-red-200 hover:border-red-600 cursor-pointer p-1 items-center w-min">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                <span class='text-sm'>Clear&nbsp;cart</span>
              </div>
            </div>
            <!-- Disabled clear cart -->
            <div class='' v-else>
              <div class="flex flex-row border-2 rounded text-gray-200 p-1 items-center w-min">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                <span class='text-sm'>Clear&nbsp;cart</span>
              </div>
            </div>
            <!-- Edit cart -->
            <div v-if='(showing_payment_div||showing_RFQ_div)'>
              <div v-on:click="modify_cart" class="flex flex-row border-2 rounded hover:bg-red-200 hover:border-red-600 cursor-pointer p-1 items-center w-min">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                </svg>
                <span class='text-sm'>Edit&nbsp;cart</span>
              </div>
            </div>
            
            <!-- Cart total sum -->
            <div v-if="this.$store.state.cart_contents.length >0" class='flex flex-col items-end'>
              <!-- <div>Total items: {{ this.$store.state.cart_contents.map(cart_line => cart_line.count).reduce((a,b)=>a+b,0) }}</div> -->
              <div class='text-lg'>
                <span class='font-bold'>Cart: </span>
                <span class='font-normal'>
                  <!-- {{ this.$root.format_locale_price(get_total_cart_locale_value()) }} -->
                  {{ this.$root.format_locale_price(this.$store.getters['get_total_cart_locale_value']) }}
                </span>
              </div>
            </div>

          </div>
        </td>
      </tr>
    </table>

    <div class='my-10' />
    


    
    <!-- Commannd buttons -->
    <div class="flex flex-col items-start">
      
      <!-- Request quotation -->
      <div class=' flex-col flex items-start'>
        <button class='flex flex-row m-1 cart_action_button' v-on:click="clicked_RFQ">
          <svg xmlns="http://www.w3.org/2000/svg" class="mt-1 mr-2 h-5 w-5 transform transition duration-300 ease-in-out" v-bind:class="{'rotate-90': showing_RFQ_div}"  fill="none" viewBox="0 0 28 28" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
          </svg>
          Request Quotation <span class='italic text-sm text-gray-400 ml-1'>(for corporate orders)</span>
        </button>
        <ExpandingDiv class='mt-0 p-1'>
          <div v-show="showing_RFQ_div">
            <RequestQuotation />
            
          </div>
        </ExpandingDiv>
      </div>

      <!-- Request cart checkout -->
      <div class=' flex-col flex items-start'>
        <button class='flex flex-row m-1 cart_action_button'
                v-on:click="clicked_paymentcheckout"
                :disabled="this.$store.state.cart_contents.length==0"
                v-bind:class="{'text-gray-300':this.$store.state.cart_contents.length==0}">
          <svg xmlns="http://www.w3.org/2000/svg" class="mt-1 mr-2 h-5 w-5 transform transition duration-300 ease-in-out" v-bind:class="{'rotate-90': showing_payment_div}"  fill="none" viewBox="0 0 28 28" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
          </svg>
          Purchase Checkout
        </button>
        <ExpandingDiv class='mt-0 p-1'>
            <div v-if="showing_payment_div">
              <PaymentCheckout />



            </div>
        </ExpandingDiv>
      </div>

    </div>


  </div>

</template>

<script>
// @ is an alias to /src
import CartQtyChange from '@/components/CartQtyChange.vue'
import ExpandingDiv from '@/components/ExpandingDiv.vue'
import RequestQuotation from '@/components/RequestQuotation.vue'
import PaymentCheckout from '@/components/PaymentCheckout.vue'


export default {
  name: 'Cart',
  components: {
    CartQtyChange,
    ExpandingDiv,
    RequestQuotation,
    PaymentCheckout,
  },
  data() {
    return {
      showing_payment_div: false,
      showing_RFQ_div: false,
    }
  },
  methods: {
    get_total_cart_locale_value2() {
      return {currency:this.$store.state.cart_contents[0].SKU.price.currency,value:this.$store.state.cart_contents.map(cart_line => cart_line.SKU.price.value * cart_line.count).reduce((a,b)=>a+b,0)}
    },
    clear_cart() {
      if (window.confirm("Clear the cart?")) {
        this.$store.commit('clear_cart');
      }
    },
    modify_cart() {
      this.showing_payment_div = false;
      this.showing_RFQ_div = false;
    },
    async clicked_RFQ() {
      await new Promise(r => setTimeout(r, 200));
      this.showing_RFQ_div = !this.showing_RFQ_div;
      this.showing_payment_div=this.showing_payment_div && !this.showing_RFQ_div;
    },
    async clicked_paymentcheckout() {
      await new Promise(r => setTimeout(r, 200));
      this.showing_payment_div = !this.showing_payment_div;
      this.showing_RFQ_div=this.showing_RFQ_div && !this.showing_payment_div;
    },    

  },
  async mounted() {

  }
}
</script>

<style scope>
  .cart_thumbnail {
    max-width:100px;
    max-height:100px;
  }
  .cart_action_button {
    @apply rounded border py-1 border-gray-400 px-2 transform active:translate-x-px hover:bg-gray-200 active:bg-gray-300;
  }

</style>
