<template>
  <div class='flex justify-center items-stretch w-full'>

    <div class='container border  max-w-screen-lg flex flex-col items-stretch'>
      <div id="nav" class='p-2 mb-4 w-full min-w-full flex flex-row items-center justify-between bg-gray-100'>
        <div id='nav_topleft'>
          <router-link to="/"><img id='img_logo' class='' src="@/assets/abounddata_logo.svg" alt="Abound Data logo"></router-link>
        </div>
        <div id='nav_topleft'>
          <img id='img_sgflag' class='border border-gray-400 inline-block' src="@/assets/sg_flag.png" alt="Singapore flag">&nbsp;
          <router-link class='font-bold hover:text-purple-700 hover:underline text-gray-600' to="/about">FAQ</router-link> |
          <router-link class='font-bold hover:text-purple-700 hover:underline' to="/cart">Cart ({{ this.$store.state.cart_contents.map(x => x.count).reduce((a,b) => a+b, 0) }} items)</router-link> 
        </div>
      </div>
      <div class='w-full'>
        <router-view  class='' style='' />
      </div>
    </div>
  </div>
</template>



<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* #nav {
  padding: 10px;
} */

/* #nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */


#img_logo {
  width:  100px;
}

#img_sgflag {
  height: 20px;
}

div {
  /* border: 1px solid red; */
}



td,table,tr,th {
  @apply border border-gray-300 px-2;
}
.hyperlink_text {
  @apply text-right text-blue-700 hover:underline
}


</style>
