<template>
  <div class="flex flex-col items-start rounded border bg-white-50 p-1 pt-0 border-gray-400">
    
    <!-- SKU row (optionally shown) -->
    <div class='-mb-2' v-if='show_SKU_uuid'>
      <span class='text-xs text-left text-gray-500'>SKU: {{ item_details.main.SKU_uuid }}</span> 
    </div>
    <!-- Price row (optionally shown) -->
    <div class='' v-if='show_price'>
      <span class='text-xs text-gray-500'>{{ this.$root.format_locale_price(item_details.price) }}</span>
    </div>

    <!-- Quantity change row -->
    <div class="flex border border-gray-100 bg-white rounded">
      <div class="cart_change_button" v-bind:onclick="decrement">
        &minus;
      </div>
      <div class="text-lg h-8 w-8">
        {{ this.$store.getters.get_current_SKU_count(item_details.main.SKU_uuid) }}
      </div>
      <div class="cart_change_button" v-bind:onclick="increment">
        &plus;
      </div>
    </div>
    
    <!-- Error message row -->
    <div>
      <span class='transition-all duration-500 text-left text-red-500 italic text-sm'>{{errormessage}}</span>
    </div>

  </div>  
</template>

<script>
export default {
  name: 'StandaloneSKUAdd',
  props: {
    item_details : Object,
    show_SKU_uuid: {default: true, type: Boolean},
    show_price: {default: true, type: Boolean},
  },
  data() {
    return {
      errormessage : '',
    }
  },
  components: {
    
  },
  methods: {
    async decrement() {
      const response = await this.$store.dispatch('decrement_cart_line',this.item_details.main.SKU_uuid);
      if (response.status=='failure') {
        this.errormessage = response.message;
      } else {
        this.errormessage = "";
      }

    },
    async increment() {
      const response = await this.$store.dispatch('add_item_to_cart',this.item_details);
      if (response.status=='failure') {
        this.errormessage = response.message;
      } else {
        this.errormessage = "";
      }
    }
  }
}



</script>

<style scoped>
  .cart_change_button {
    @apply text-lg h-8 w-8 border rounded border-gray-700 hover:bg-gray-200 active:bg-red-200 cursor-pointer
  }
</style>
