<template>
  <div class='paymentdiv flex-col flex items-start text-sm text-gray-700 border-2 rounded border-gray-400 p-2'>

    <div></div>
    <div class='w-full' id="paypal-buttonselem"></div>

  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";


export default {
  name: 'PaymentCheckout',
  components: {

  },
  data() {
    return {
      // paypal_env: 'sandbox',
      // paypal_clientid: {
      //   sandbox: 'Ae-N3yzkr...', // Client ID
      //   production: 'AQUMOAEh6...' // Client ID
      // },
      paypal_env: process.env.VUE_APP_PAYPAL_ENV,
      paypal_buttons: null,
      cart_uuid: '',
    }
  },
  async mounted() {
    const vue_instance = this;
    const paypal_SDK = await loadScript({'client-id':process.env.VUE_APP_PAYPAL_CLIENTID,
                                         'currency':'SGD',
                                         'intent':'authorize',
                                         });
    this.paypal_buttons = paypal_SDK.Buttons({
      createOrder: async function(data,actions) {
        // Gets a cart_uuid from the API server first.
        const response = await fetch(vue_instance.api_root.replace(/\/$/, "") + "/api/carts/lockcart",{
          method: 'POST',
          credentials: 'include',
          headers: {'Accept':'application/json','Content-Type':'application/json'},
          //body: JSON.stringify({'RFQForm':this.RFQForm,'cart':this.$store.state.cart_contents})});
          body: JSON.stringify({'reason':'Pre-payment cart lock','cart':vue_instance.$store.getters['get_cart_essential_details']})});
        const cart_uuid = (await response.json()).cart_uuid;


        // https://stackoverflow.com/a/56417318 for syntax
        const order_details = {
          purchase_units: [{
                amount: {
                    value: vue_instance.$store.getters['get_total_cart_locale_value'].value,
                    currency_code: vue_instance.$store.getters['get_total_cart_locale_value'].currency,
                    breakdown: {item_total: {value: vue_instance.$store.getters['get_total_cart_locale_value'].value, currency_code: vue_instance.$store.getters['get_total_cart_locale_value'].currency}}
                },
                soft_descriptor: 'Abound Data',
                //invoice_id: 'invoice_id', // But Paypal auto-generates an invoice ID
                reference_id: cart_uuid,
                items: vue_instance.$store.state.cart_contents.map(cart_line => ({
                  name: cart_line.SKU.main.SKU_title,
                  description: cart_line.SKU.main.SKU_description,
                  unit_amount: {value: cart_line.SKU.price.value, currency_code: cart_line.SKU.price.currency},
                  quantity: cart_line.count,
                  sku: cart_line.SKU.main.SKU_uuid,
                })),
            }],
        application_context: {brand_name: "Abound Data"},
        }
        console.log('Creating orders',order_details);
        return actions.order.create(order_details)
      },
      onApprove: async function(data,actions) {
        return actions.order.authorize().then(async function(details) {
          console.log('onApproved,',data,details);
          
          // Report the approval to the API server
          const response = await fetch(vue_instance.api_root.replace(/\/$/, "") + "/api/carts/notifypayment",{
            method: 'POST',
            credentials: 'include',
            headers: {'Accept':'application/json','Content-Type':'application/json'},
            //body: JSON.stringify({'RFQForm':this.RFQForm,'cart':this.$store.state.cart_contents})});
            body: JSON.stringify({paypal_response:details})
          });
          const notification_receipt = await response.json();
          console.log('Notification receipt',notification_receipt);

          // Redirects to thank-you page.
          vue_instance.$router.push({ name: 'payment_status', params: { payment_processor_response_str: JSON.stringify(details) } });
        })
      },
      onCancel: function (data) {
        // Maybe we can emit a signal so that the cart goes back into the edit mode
        console.log('onCancel,',data);
      },
      onError: function(err) {
        console.log('Error with checking out',err);
      },
      onShippingChange: function(data, actions) {
        if (data.shipping_address.country_code !== 'SG') {
          return actions.reject();
        }
        return actions.resolve();
      }
    }, this);
    this.paypal_buttons.render('#paypal-buttonselem');

  },
  beforeUnmount() {
    this.paypal_buttons.close();
  },
}




</script>

<style scoped>
  .paymentdiv {
    min-width:550px;
  }
</style>
