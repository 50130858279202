import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js'
import './styles/tailwind.css';
import {helperMixins} from './helper.js'
  

const app = createApp({extends:App,mixins:[helperMixins]});

//app.config.globalProperties.api_root = 'http://127.0.0.1:5000/'; // Then we can access this anywhere from this.api_root
//app.config.globalProperties.api_root = 'https://www.abounddata.com/'; // Then we can access this anywhere from this.api_root
app.config.globalProperties.api_root = process.env.VUE_APP_APIROOT; // Then we can access this anywhere from this.api_root

app.use(store).use(router).mount('#app')

